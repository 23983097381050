.homeBanner {
  height: 310px;
  width: 100%;
  padding: 20px 0;
  position: relative;
  // &::before {
  //   content: '';
  //   width: 8%;
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   display: block;
  //   background-color: hsla(0, 0%, 100%, 0.54);
  //   z-index: 2;
  // }
  // &::after {
  //   right: 0;
  //   content: '';
  //   width: 8%;
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   display: block;
  //   background-color: hsla(0, 0%, 100%, 0.54);
  //   z-index: 2;
  // }

  .homeBanner_swiper {
    width: 100%;
    height: 100%;

    .mobile {
      display: none;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: fill;
      object-position: top;
      border-radius: 30px;
      box-shadow: 0 10px 12px -5px rgba(0, 0, 0, 0.1),
        0 8px 10px -6px rgba(0, 0, 0, 0.1);
    }
  }

  :global {
    .swiper-pagination-bullet-active {
      background-color: #fff !important;
    }

    // .swiper-slide {
    //   width: 84% !important;
    //   margin: 0 8%;
    // }

    .swiper-button-next,
    .swiper-button-prev {
      color: #fff;
      background-color: rgba($color: #000000, $alpha: 0.5);
      padding: 30px;
      border-radius: 50%;
      text-align: center;

      &::after {
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .homeBanner {
    height: 200px;

    .homeBanner_swiper {
      .mobile {
        display: block;
        width: 100%;
        height: 100%;
      }
      .pc {
        display: none;
      }

      img {
        object-position: center;
      }
    }

    :global {
      .swiper-button-next,
      .swiper-button-prev {
        width: 30px;
        height: 30px;
        padding: 10px !important;

        &::after {
          font-size: 14px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .homeBanner {
    :global {
      .swiper-button-next {
        right: 15% !important;
      }

      .swiper-button-prev {
        left: 15% !important;
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  .homeBanner {
    :global {
      .swiper-button-next {
        right: 22% !important;
      }

      .swiper-button-prev {
        left: 22% !important;
      }
    }
  }
}
