.header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: #a243b5;
  box-shadow: 2px 2px 4px rgba(83, 100, 122, 0.3);

  .header_wrapper {
    width: 1200px;
    height: 4.75rem;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    .logo_wrapper {
      color: #fff;
      font-size: 2rem;
    }
  }
}

@media screen and (max-width: 1100px) {
  .header {
    height: 4rem;

    .header_wrapper {
      width: 100vw;
      justify-content: space-between;

      .logo_wrapper {
        width: 10rem;
        padding: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
