.update_today{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 16%;
    margin-top: 10px;
    .num{
        font-size: 18px;
        font-weight: bold;
        padding-left: 4px;
    }
}

.select {
  margin-bottom: 30px;

  .title_container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;

    .title {
      padding: 0 0.875rem;
      font-size: 2rem;
      font-weight: 600;
      background-color: #f5f5f5;
      z-index: 9;
      text-align: center;
    }
    // &::before {
    //   left: 0;
    //   position: absolute;
    //   top: 50%;
    //   background: #ddd;
    //   content: '';
    //   height: 1px;
    //   width: 50%;
    // }
    // &::after {
    //   right: 0;
    //   position: absolute;
    //   top: 50%;
    //   background: #ddd;
    //   content: '';
    //   height: 1px;
    //   width: 50%;
    // }
  }

  .sub_title {
    display: flex;
    justify-content: center;
    .link {
      position: relative;
      display: inline-block;
      padding: 1.2rem;
      text-align: center;
      font-size: 1.2rem;
      font-weight: 700;

      &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 0 0 0.5rem;
        content: '>>';
        font-size: 1rem;
        color: #333;
      }

      h3 {
        display: inline-block;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .select {
    margin-bottom: 10px;
    .title_container {
      padding: 1.5rem 0;

      .title {
        font-size: 1.5rem;
      }
    }
  }
}
